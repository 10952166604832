import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from '@stores/cart'
import { Layout, Head, Loader } from '@common'
import { onInit, PurchaseCompleted, PurchaseFailed } from '@pages/Summary'
import { navigate } from '@reach/router'
import './styles/summary.scss'

class Summary extends Component {
	state = {
		imBusy: true,
		order: '',
		error: false,
	}
	componentWillMount() {
		if (this.props.location.search.includes('error')) {
		}
		else{
			if (this.props.location.search.includes('id')) {
				onInit(this)
			}
		}
	}

	componentDidMount() {
		if (
			this.props.location.search.includes('error') &&
			this.props.location.search.includes('501')
		) {
			this.setState({
				order: {
					status: 'canceled',
				},
				error: true,
				imBusy: false
			})
		}
		else{
			if (this.props.location.search.includes('id')) {
			}
			else{
				this.setState({
					order: {
						status: 'canceled',
					},
					error: true,
					imBusy: false
				})
			}
		}
	}

	componentDidUpdate() {
		if (this.state.order.status === 'completed') {
			this.props.resetCart()
			setTimeout(() => {
				navigate('/')
			}, 5000)
		}
		if (this.state.order.status === 'canceled') {
			setTimeout(() => {
				navigate('/')
			}, 5000)
		}
	}
	
	render() {
		const { pageContext } = this.props
		const { order, imBusy, error } = this.state
		const title = error ? "Coś poszło nie tak, proszę spróbuj ponownie, za chwilę zostaniesz przekierowany na stronę główną" : "Dziękujemy za zakupy, za chwilę zostaniesz przekierowany na stronę główną"
		return (
			<Layout imBusy={imBusy} seo={pageContext.page.seo}
			title={title}
			siteMetaData={pageContext.page.siteMetaData}>
				<div className='summary-status'>
					<div className="container">
						{order.status === 'completed' ? 
							<PurchaseCompleted order={order} /> : 
							<PurchaseFailed order={order} />}
					</div>
				</div>
			</Layout>
		)
	}
}

const mapStateToProps = (state) => ({})
const mapActionToDispatch = { ...actions }

export default connect(mapStateToProps, mapActionToDispatch)(Summary)
