import React from 'react'

const PurchaseCompleted = ({ order }) => {
	return (
		<div className='summary'>
			<div className='summary-stats'>
				<div className='row'>
					<div className='col'>
						<h3>
							Numer zamówienia: <strong>{order.id}</strong>
						</h3>
					</div>
					<div className='col'>
						<h3>
							Status: <strong>Gotowe do wysyłki</strong>
						</h3>
					</div>
				</div>
			</div>
		</div>
	)
}
export default PurchaseCompleted
