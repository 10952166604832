import React from 'react'
const PurchaseFailed = ({order})=>{
    return (<div className='summary'>
    <div className='summary-stats'>
        <div className='row'>
            <div className='col'>
                <h3>
                    Status: <strong>Nieopłacone</strong>
                </h3>
            </div>
        </div>
    </div>
</div>)
    // return ['tresc błędu', 'nr zamówienia', 'link do platnosci', 'contact ze sklepem'].join(' | ')
}
export default PurchaseFailed