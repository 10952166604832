import {WPApi, PayUApi} from '@api'

const onInit = (ctx) => {
    const {search} = ctx.props.location
    const paymentId = Number(search.replace('?id=',''))
    
    if(!Number.isNaN(paymentId)){
        // jest inny niż completed
        return WPApi.searchByPaymentId(paymentId).then(([order, ...rest])=>{
            if(!order){
                    return Promise.resolve(true)
                }
            if(order.status === 'processing'){
                return PayUApi.getTransactionStatus(order.meta_data[0].value)
                    .then((data) => {
                        // można założyć że data nie będzie miała orders
                        // ktore jest array
                        if(Array.isArray(data.orders) && data.orders.length === 1){
                            const [payment] = data.orders
                            if(payment.status === 'COMPLETED'){
                                return WPApi.changeOrder(paymentId).then((data)=>{
                                    ctx.setState({
                                        imBusy:false,
                                        order: data
                                    })
                                    return true
                                }).catch((err)=>{
                                    ctx.setState({
                                        imBusy: false,
                                        order: order,
                                        error: err
                                    })
                                    return err
                                })
                            }
                            
                            else{
                                alert('Wystąpił problem z płatnością')
                                // jest inny niż completed
                                // show error - błąd z transakcją oraz treść błędu
                                ctx.setState({
                                    imBusy:false,
                                    order: order
                                })
                                return Promise.resolve(true)
                            }
                        }

                        else{
                        // console.log('err1')

                            // show error - bład spójności danych
                            ctx.setState({
                                imBusy:false,
                                error: data
                            })
                            return data
                        }
                    })
                    .catch((err)=>{
                        // console.log('err2')
                        ctx.setState({
                            imBusy: false,
                            order: order,
                            error: err
                        })
                        return err
                    })
            }
            else{
                // status inny niż processing
                // console.log('err3')

                ctx.setState({
                    imBusy:false,
                    order: order
                })
                return order
            }
        })
        .catch((error)=>{
            // console.log('err4')
            return error
        })
    }
    else{
        // console.log('err5')
        return Promise.resolve(true)
    }
}

export { onInit }